import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { CuponsService } from '../shared/cupons-service.service';

const searchById = gql`
  query ($userId: String!) {
    cupons(where: { userId: { eq: $userId } }) {
      nodes {
        id
        status
        updatedAt
        promotion {
          code
        }
        cuponsOptions {
          id
          value
          option {
            id
            code
            name
          }
        }
      }
    }
  }
`;

class CuponsById {
  id: string;
  status: string;
  updatedAt: string;
  code: string;
  cuponsOptions: CuponsOptions[];
}

class CuponsOptions {
  id: string;
  value: string;
  option: Option;
}

class Option {
  id: string;
  code: string;
  name: string;
}

@Component({
  selector: 'app-cupns-by-id',
  templateUrl: './cupons-by-id.page.html',
  styleUrls: ['./cupons-by-id.page.scss'],
})
export class CuponsByIdPage implements OnInit {
  dataColumns = [
    { header: 'id', field: 'id' },
    { header: 'status', field: 'status' },
    { header: 'last-update', field: 'updatedAt' },
    { header: 'promotion', field: 'code' },
  ];
  cupons: CuponsById[] = [];
  loaded: boolean = false;
  selectedRow: CuponsById;

  constructor(
    private apollo: Apollo,
    private router: ActivatedRoute,
    public cuponsService: CuponsService
  ) {
    this.router.params.subscribe((params) => {
      this.apollo
        .watchQuery({
          query: searchById,
          variables: {
            userId: params['userId'],
          },
        })
        .valueChanges.subscribe((result: any) => {
          console.log(result);
          // this.data = [...result.data.cupons.nodes];
          let cupon: CuponsById;
          result.data.cupons.nodes.forEach((element, index) => {
            let datetime = new Date(element.updatedAt);
            // console.log(datetime.toLocaleDateString());
            cupon = {
              id: element.id,
              status: element.status,
              updatedAt: datetime.toLocaleDateString(),
              code: element.promotion.code,
              cuponsOptions: element.cuponsOptions,
            };
            this.cupons.push(cupon);
            this.loaded = true;
          });
        });
    });
  }

  ngOnInit() {}

  onRowSelect(event) {
    console.log(event.data);
  }

  onRowUnselect(event) {
    console.log(event.data);
  }
}
