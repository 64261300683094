import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CuponsByIdPage } from './cupons-by-id/cupons-by-id.page';

const routes: Routes = [
  {
    path: 'cupons/:userId',
    component: CuponsByIdPage,
  },
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'promotions-by-id',
    loadChildren: () =>
      import('./promotions-by-id/promotions-by-id.module').then(
        (m) => m.PromotionsByIdPageModule
      ),
  },
  {
    path: 'cupons-by-id',
    loadChildren: () =>
      import('./cupons-by-id/cupons-by-id.module').then(
        (m) => m.CuponsByIdPageModule
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
