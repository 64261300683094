import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
const createCupon = gql`
  mutation createCupon($promotionId: Int!, $userId: String!, $operator: String) {
    createCupon(cupon: { promotionId: $promotionId, userId: $userId, operator: $operator }) {
      id
      promotionId
      userId
      status
      createdAt
    }
  }
`;

const updateCupon = gql`
  mutation updateCupon($id: String!, $status: String!) {
    updateCupon(id: $id, update: { status: $status }) {
      status
      id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CuponsService {
  states = ['WAITING', 'DELIVERED', 'EXPIRED', 'CANCELED'];
  constructor(private apollo: Apollo) {}

  onCreateCupon(cupon: { promotionId: number; userId: string; operator: string }) {
    return this.apollo.mutate({
      mutation: createCupon,
      variables: {
        promotionId: cupon.promotionId,
        userId: cupon.userId,
        operator: cupon.operator
      },
    });
  }

  onEditCupon(cupon: any) {
    return this.apollo.mutate({
      mutation: updateCupon,
      variables: {
        id: cupon.id,
        status: cupon.status,
      },
    });
  }
}
