import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [ConfirmationService],
})
export class AppComponent implements OnInit {
  lang = 'en';
  date: any;
  subscription: Subscription;
  //https://www.ibidemgroup.com/edu/internacionalizacion-i18n-primeng-ngx-translate/

  constructor(
    private primengConfig: PrimeNGConfig,
    public translate: TranslateService
  ) {
    this.translate.addLangs(['en', 'es', 'pt']);
    this.translate.setDefaultLang('en');

    const browserLang = this.translate.getBrowserLang();
    this.lang = browserLang.match(/en|es|pt/) ? browserLang : 'pt';
    this.changeLang(this.lang);

    this.subscription = this.translate.stream('primeng').subscribe((data) => {
      this.primengConfig.setTranslation(data);
    });

    //TEST CHANGE LANG
    this.changeLang('pt');
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  changeLang(lang: string = null) {
    if (lang) {
      this.translate.use(lang);
      this.lang = lang;
    } else {
      this.translate.use(this.lang);
    }
  }
}
